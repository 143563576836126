import React from 'react'
import 'twin.macro'

export default function ScrollImage({withText}) {
    return (
        <div tw="relative w-full my-12">
  <div tw="absolute inset-x-0 bottom-0 h-1/2"></div>
  <div tw="max-w-7xl mx-auto sm:px-6 lg:px-8">
    <div tw="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
      <div tw="absolute inset-0">
        <img tw="h-auto w-full object-cover" src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100" alt="People working on laptops" />
        <div tw="absolute inset-0 bg-secondary-700" style={{mixBlendMode: 'multiply'}}></div>
      </div>
      <div tw="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
        <h1 tw="md:px-40 md:py-24  text-center text-2xl font-extrabold tracking-tight sm:text-3xl lg:text-4xl">
          <span tw="block text-white">“IN THE END, WE ONLY REGRET THE CHANCES WE DIDN’T TAKE.”
</span>
          {/* <span tw="block text-secondary-200">customer support</span> */}
        </h1>
        <p tw="mt-6 max-w-lg mx-auto text-center text-xl text-secondary-200 sm:max-w-3xl">–LEWIS CARROLL</p>
      </div>
    </div>
  </div>
</div>

    )
}
